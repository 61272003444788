<i18n>
{
  "de": {
    "addButton": "Kategorisierung hinzufügen",
    "nameTitle": "Name",
    "editButton": "Bearbeiten",
    "deleteConfirmation": "Wollen Sie diese Kategorisierung wirklich löschen? Dabei gehen alle Zuordnungen zu Gebäuden verloren.",
    "pageTitle": "Kategorisierungen",
    "typeTitle": "Typ",
    "typeAuto": "Automatisch",
    "typeManual": "Manuell",
    "nTags": "Kategorien",
    "noGroups": "In diesem Portfolio sind noch keine Kategorisierungen vorhanden."
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady" class="tag-groups-page">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
      <detail-list class="tag-groups-table" v-if="portfolio.tag_groups.length">
        <template #header>
          <span>{{ $t('nameTitle') }}</span>
          <span>{{ $t('typeTitle') }}</span>
          <span class="align-right">{{ $t('nTags') }}</span>
          <span></span>
        </template>
        <li class="tag-group-row" v-for="(tag_group, index) in portfolio.tag_groups" v-bind:key="index">
          <span v-if="tag_group.auto">{{ $t(`_tagGroupNames.${tag_group.name}`) }}</span>
          <span v-else>{{ tag_group.name }}</span>
          <span v-if="tag_group.auto">{{ $t('typeAuto') }}</span>
          <span v-else>{{ $t('typeManual') }}</span>
          <span class="align-right">{{ tag_group.tags.length }}</span>
          <list-button-wrapper v-if="!tag_group.auto">
            <list-edit-button v-if="portfolio.permissions.EDIT_TAGS" @click="editTagGroupId = tag_group.id; editModalOpen = true;" />
            <list-delete-button v-if="portfolio.permissions.EDIT_TAGS" @click="onDelete(tag_group)" />
          </list-button-wrapper>
          <span v-else></span>
        </li>
      </detail-list>
      <div v-else>
        {{ $t('noGroups') }}
      </div>
      <button-wrapper>
        <a class="button" v-if="portfolio.permissions.EDIT_TAGS" href="#" @click.prevent="editTagGroupId = null; editModalOpen = true;">{{ $t('addButton') }}</a>
      </button-wrapper>
      <edit-tag-group-modal :portfolio="portfolio" :tag-group-id="editTagGroupId" v-if="editModalOpen" @close="editModalOpen = false" />
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ListEditButton from '@/components/shared/lists/ListEditButton'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import DetailList from '@/components/shared/lists/DetailList'
import EditTagGroupModal from '@/components/settings/tag_groups/EditTagGroupModal'
import { mapActions } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin // Provides: portfolio, portfolioLoading, portfolioReady
  ],
  data: function () {
    return {
      editTagGroupId: null,
      editModalOpen: false
    }
  },
  methods: {
    ...mapActions({
      deleteTagGroup: 'portfolio/deleteTagGroup'
    }),
    onDelete (tagGroup) {
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        this.editing = false
        this.deleteTagGroup(tagGroup)
      }
    }
  },
  components: {
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    ButtonWrapper,
    MainLayout,
    DetailList,
    EditTagGroupModal
  }
}
</script>

<style>
.tag-groups-page .tag-groups-table.detail-list > ul > li {
  grid-template-columns: 1fr 100px 120px 120px;
}
.tag-groups-page .align-right {
  text-align: right;
}
</style>
