<i18n>
{
  "de": {
    "descriptionLabel": "Bezeichnung",
    "alreadyExistsError": "Name bereits vorhanden"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-tag-group">
    <form-row :label="$t('descriptionLabel')">
      <text-input :min-length="1" v-model="model.name" :edit="isEditing" :invalid-values="invalidValues" :invalid-values-error-message="$t('alreadyExistsError')" />
    </form-row>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import TextInput from '@/components/shared/forms/TextInput'
import FormRow from '@/components/shared/forms/FormRow'

export default {
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    tagGroup: { // If null, a new tag group is created
      type: Object
    }
  },
  computed: {
    invalidValues () {
      if (this.value) {
        return this.portfolio.tag_groups.filter(t => t.name !== this.value.name).map(t => t.name)
      } else {
        return this.portfolio.tag_groups.map(t => t.name)
      }
    }
  },
  watch: {
    portfolio () {
      this.resetModel()
    },
    tagGroup () {
      this.resetModel()
    }
  },
  created: function () {
    if (!this.tagGroup) {
      // Start editing immediately when adding a new group
      this.onFormEvent('edit')
    }
    this.resetModel()
  },
  methods: {
    ...mapActions({
      addTagGroup: 'portfolio/addTagGroup',
      updateTagGroup: 'portfolio/updateTagGroup'
    }),
    resetModel () {
      if (this.tagGroup) {
        this.model = {
          'name': this.tagGroup.name
        }
      } else {
        this.model = {
          'name': ''
        }
      }
    },
    async saveHandler () {
      if (this.tagGroup) {
        // Edit
        this.updateTagGroup({
          id: this.tagGroup.id,
          tagGroup: {
            'name': this.model.name
          }
        })
      } else {
        // Create new
        this.addTagGroup({
          'portfolio_id': this.portfolio.id,
          'name': this.model.name
        })
      }
    }
  },
  components: {
    TextInput,
    EditContainer,
    FormRow
  }
}
</script>
