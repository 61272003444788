<i18n>
{
  "de": {
    "modalTitle": "Kategorisierung"
  }
}
</i18n>

<template>
  <modal @close="$emit('close', $event)" class="edit-tag-group-modal">
    <template v-slot:header>
        <h2>{{ $t('modalTitle') }}</h2>
    </template>
    <div>
      <edit-tag-group
        :portfolio="portfolio"
        :allow-edit="portfolio.permissions.EDIT_TAGS"
        :tag-group="tagGroup"
        @cancel="onCancel"
        @save="onSave"
      />
      <edit-tag-group-tags
        :tag-group="tagGroup"
        :portfolio="portfolio"
        v-if="tagGroupId !== null"
      />
    </div>
  </modal>
</template>

<script>
import EditTagGroup from '@/components/settings/tag_groups/EditTagGroup'
import EditTagGroupTags from '@/components/settings/tag_groups/EditTagGroupTags'
import Modal from '@/components/shared/Modal'

export default {
  props: {
    portfolio: {
      type: Object
    },
    tagGroupId: {
      type: Number
    }
  },
  computed: {
    tagGroup () {
      return this.portfolio.tag_groups.find(g => g.id === this.tagGroupId)
    }
  },
  methods: {
    onCancel () {
      if (this.tagGroupId === null) {
        // Close dialog if adding new is canceled
        this.$emit('close')
      }
    },
    onSave () {
      if (this.tagGroupId === null) {
        // Close dialog if adding new is saved
        this.$emit('close')
      }
    }
  },
  components: {
    Modal,
    EditTagGroup,
    EditTagGroupTags
  }
}
</script>
