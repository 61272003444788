<i18n>
{
  "de": {
    "loadingLabel": "Kategorien werden geladen",
    "addButton": "Kategorie hinzufügen",
    "nameTitle": "Name",
    "editButton": "Bearbeiten",
    "cancelButton": "Abbrechen",
    "saveButton": "Speichern",
    "alreadyExistsError": "Name bereits vorhanden",
    "pendingLabel": "Wird gespeichert...",
    "deleteConfirmation": "Wollen Sie diese Kategorie wirklich löschen? Dabei gehen alle Zuordnungen zu Gebäuden verloren.",
    "nBuildingsTitle": "Anzahl Gebäude",
    "noTags": "Es sind noch keine Kategorien vorhanden"
  }
}
</i18n>

<template>
  <div class="edit-tag-group-tags">
    <detail-list class="tags-table" v-if="tagGroup && tagGroup.tags.length || addingTag">
      <template #header>
        <span>{{ $t('nameTitle') }}</span>
        <span></span>
      </template>
      <li class="tag-row" v-for="(tag, index) in tagGroup.tags" v-bind:key="index">
        <span v-if="editTag && editTag.id === tag.id">
          <text-input :min-length="1" v-model="tagNameModel" :invalid-values="invalidValues" :invalid-values-error-message="$t('alreadyExistsError')" :edit="true" />
        </span>
        <span v-else>{{ tag.name }}</span>
        <list-button-wrapper v-if="editTag && editTag.id === tag.id">
          <list-button @click="onEditCancel" :img-src="'/icons/x-circle.svg'" :label="$t('cancelButton')" />
          <list-save-button @click="onEditSave" />
        </list-button-wrapper>
        <list-button-wrapper v-else>
          <list-edit-button v-if="portfolio.permissions.EDIT_TAGS && !editTag && !addingTag" @click="onEditTag(tag)" />
          <list-delete-button v-if="portfolio.permissions.EDIT_TAGS && !editTag && !addingTag" @click="onDelete(tag)" />
        </list-button-wrapper>
      </li>
      <li class="tag-row" v-if="addingTag">
        <text-input :min-length="1" v-model="tagNameModel" :invalid-values="invalidValues" :invalid-values-error-message="$t('alreadyExistsError')" :edit="!pending" />
        <span v-if="pending">
          {{ $t('pendingLabel') }}
        </span>
        <list-button-wrapper v-else>
          <list-button @click="onAddCancel" :img-src="'/icons/x-circle.svg'" :label="$t('cancelButton')" />
          <list-save-button @click="onAddSave" />
        </list-button-wrapper>
      </li>
    </detail-list>
    <div v-else>
      {{ $t('noTags') }}
    </div>
    <button-wrapper>
      <a class="button" v-if="portfolio.permissions.EDIT_TAGS && !editTag && !addingTag" href="#" @click.prevent="onAddTag">{{ $t('addButton') }}</a>
    </button-wrapper>
  </div>
</template>

<script>
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListButton from '@/components/shared/lists/ListButton'
import ListEditButton from '@/components/shared/lists/ListEditButton'
import ListSaveButton from '@/components/shared/lists/ListSaveButton'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import TextInput from '@/components/shared/forms/TextInput'
import DetailList from '@/components/shared/lists/DetailList'
import { mapActions } from 'vuex'

export default {
  props: {
    portfolio: {
      type: Object
    },
    tagGroup: {
      type: Object
    }
  },
  data: function () {
    return {
      editTag: null,
      tagNameModel: null,
      addingTag: false,
      pending: false
    }
  },
  computed: {
    invalidValues () {
      return this.tagGroup.tags.filter(t => !this.editTag || t.id !== this.editTag.id).map(t => t.name)
    }
  },
  methods: {
    ...mapActions({
      deleteTag: 'portfolio/deleteTag',
      addTag: 'portfolio/addTag',
      updateTag: 'portfolio/updateTag',
      refreshDetailsById: 'portfolio/refreshDetailsById'
    }),
    onAddTag () {
      this.editTag = null
      this.tagNameModel = ''
      this.addingTag = true
    },
    onAddCancel () {
      this.addingTag = false
    },
    async onAddSave () {
      this.pending = true
      await this.addTag({
        'group_id': this.tagGroup.id,
        'name': this.tagNameModel
      })
      await this.refreshDetailsById(this.portfolio.id)
      this.pending = false
      this.addingTag = false
    },
    onEditTag (tag) {
      this.tagNameModel = tag.name
      this.editTag = tag
      this.addingTag = false
    },
    onEditCancel () {
      this.editTag = null
    },
    async onEditSave () {
      await this.updateTag({
        'id': this.editTag.id,
        'tag': {
          'name': this.tagNameModel
        }
      })
      await this.refreshDetailsById(this.portfolio.id)
      this.editTag = null
    },
    async onDelete (tag) {
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        await this.deleteTag(tag)
        await this.refreshDetailsById(this.portfolio.id)
      }
    }
  },
  components: {
    ListButton,
    ListButtonWrapper,
    ListEditButton,
    ListSaveButton,
    ListDeleteButton,
    ButtonWrapper,
    DetailList,
    TextInput
  }
}
</script>

<style>
.edit-tag-group-tags .tags-table.detail-list > ul > li {
  grid-template-columns: 1fr 120px;
}
</style>
